<template>
  <div>
    <v-row justify="start">
      <v-col cols="12" sm="6" md="3" class="ml-3">
        <v-text-field
          id="campo_pesquisar"
          v-model="searchQuery"
          @input="filtrar"
          append-icon="search"
          :label="$t('label.pesquisar')"
          single-line
          clearable
          hide-details>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3" align-self="center">
        <v-row>
          <v-tooltip bottom v-if="canAccessCRUD && tipoImportacao">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_importar" icon class="mx-0" v-on="on" @click="importarDados">
                <v-icon>backup</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.importar') }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="canAccessCRUD">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_exportar" icon :disabled="bloquearBotaoExportacao" class="mx-0" v-on="on" @click="exportar()">
                <v-icon>get_app</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.exportar') }}</span>
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>

    <v-data-table
      id="tabela_clientes_indireto"
      :headers="headers"
      :items="clientesIndireto"
      class="custom-pagination-left"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }"
      >
      <template v-slot:item="{ item }">
        <tr :key="item.id">
          <td class="justify-center px-0">
            <v-tooltip bottom v-if="canAccessCRUD">
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click.stop="editar(item)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.editar') }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!canAccessCRUD">
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click="ver(item)">
                  <v-icon>equalizer</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.mais_detalhes') }}</span>
            </v-tooltip>
          </td>
          <td>{{ item.nomDistribuidor }}</td>
          <td>{{ item.cnpjDistribuidor}}</td>
          <td>{{ item.nomUf }}</td>
          <td>{{ item.nomCidade }}</td>
          <td>{{ item.nomClienteIndireto }}</td>
          <td>{{ item.desCnpj }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ClienteIndiretoListDados',
  props: {
    canAccessCRUD: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clienteIndiretoResources: this.$api.clienteIndireto(this.$resource),
      bloquearBotaoExportacao: false,
      clientesIndireto: [],
      clienteIndiretoSelecionado: {},
      esperar: false,
      pagination: {},
      totalPage: 0,
      searchQuery: '',
      headers: [
        {
          text: '', value: 'acoes', sortable: false, width: '4%', align: 'center',
        },
        { text: this.$tc('label.distribuidor', 1), value: 'nom_distribuidor', sortable: true },
        { text: this.$tc('label.cnpj_distribuidor', 1), value: 'cnpj_distribuidor', sortable: true },
        { text: this.$tc('label.estado', 1), value: 'nom_uf', sortable: true },
        { text: this.$tc('label.cidade', 1), value: 'nom_cidade', sortable: true },
        { text: this.$tc('label.razao_social_cliente', 1), value: 'nom_cliente_indireto', sortable: true },
        { text: this.$tc('label.cnpj_cpf', 1), value: 'des_cnpj', sortable: true },
      ],
    };
  },
  computed: {
    ...mapGetters('importacao', {
      tiposImportacao: 'getTiposImportacao',
    }),
    tipoImportacao() {
      return this.tiposImportacao.find((el) => el.nome === 'importacao_cliente_indireto_akzo');
    },
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    searchQuery() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.pagination.page = 1;
        this.filtrar();
      }, 500);
    },
  },
  methods: {
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    buscar() {
      const params = {
        filter: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };
      this.clienteIndiretoResources.listarFiltrado(params).then((response) => {
        this.clientesIndireto = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
        this.selected = [];
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    editar(item) {
      this.$router.push({ name: 'editarClienteIndireto', params: { id: item.id } });
    },
    getSearchQuery() {
      return this.searchQuery;
    },
    ver(item) {
      this.$router.push({ name: 'verClienteIndireto', params: { id: item.id } });
    },
    toggleAll() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.clientesIndireto.slice();
      }
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    importarDados() {
      this.$emit('TABELA_IMPORTAR_DADOS');
    },
    exportar() {
      this.bloquearBotaoExportacao = true;
      this.$emit('TABELA_EXPORTAR_DADOS',
        () => { this.bloquearBotaoExportacao = false; });
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
