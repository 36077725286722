<template>
  <div >
    <v-card class="card-size">
      <v-card-title>
        <h2 class="headline mb-0">{{ $tc('label.clientes_pequeno_porte', 1) }}</h2>
      </v-card-title>

      <cliente-indireto-sidebar
        v-if="canAccessCRUD && tipoImportacao"
        ref="sidebar">
      </cliente-indireto-sidebar>

      <cliente-indireto-list-dados :canAccessCRUD="canAccessCRUD"
        @TABELA_EXPORTAR_DADOS="requestExportacao"
        @TABELA_IMPORTAR_DADOS="$refs.sidebar.open()"
        ref="tabelaClienteIndireto">
      </cliente-indireto-list-dados>

      <v-card-actions v-if="canAccessCRUD">
        <v-row>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn id="btn_novo" fab @click="novo" class="v-btn__fab" color="primary" v-on="on" bottom right fixed>
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>{{$t('label.novo')}}</span>
          </v-tooltip>
        </v-row>
      </v-card-actions>

    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '../../produto/common/functions/roles-computed-generator';
import exportacao from '../../produto/common/functions/exportacao';
import ClienteIndiretoSidebar from './ClienteIndiretoSidebar';
import ClienteIndiretoListDados from './ClienteIndiretoListDados';

export default {
  name: 'ClientePequenoPorteList',
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
    };
  },
  components: {
    ClienteIndiretoListDados,
    ClienteIndiretoSidebar,
  },
  computed: {
    ...generateComputed('CLIENTE', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    ...mapGetters('importacao', {
      tiposImportacao: 'getTiposImportacao',
    }),
    tipoImportacao() {
      return this.tiposImportacao.find((el) => el.nome === 'importacao_cliente_indireto_akzo');
    },
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    novo() {
      this.$router.push({ name: 'novoClienteIndireto' });
    },
    requestExportacao(cb) {
      const params = {
        filtro: this.$refs.tabelaClienteIndireto.getSearchQuery() || '',
      };
      exportacao.exportar(cb, 'cliente-indireto-akzo', this, params, 5000, 0);
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
